<template>
    <div  class="main-background filter-page-container">
        <div class="filter-page-back-button" @click="$emit('setIsChangePage', 'cart')">
            <chevron-left-icon class="back-button-icon" color="#4D4C4C"/>
            <p class="back-button-text">Оформлення замовлення</p>
        </div>
        <div id="makeOrderContent" class="make-order-page-inputs-container">
            <p class="make-order-page-main-title">Оформлення замовлення</p>
            <p class="make-order-page-under-main-title">Введіть ваші контактні дані</p>
              <div class="make-order-input-container">
                <label class="make-order-input-label" for="text">Ім'я</label>
                  <input v-model="customerName" class="make-order-input" @keyup.enter="handleEnterKey" minlength="3" maxlength="30" type="text" id="text" placeholder="Ivan">
                   <p v-if="isRequiredError && !customerName.length" class="error-message-text">Обов'язкове поле</p>
                   <p v-if="isRequiredError && customerName.length && customerName.length < 3" class="error-message-text">Поле має містити більше ніж 2 символи</p>
             </div>
             <div class="make-order-input-container">
                    <label class="make-order-input-label" for="text">Прізвище</label>
                      <input v-model="customerSecondName" class="make-order-input" @keyup.enter="handleEnterKey" minlength="3" maxlength="30" type="text" id="text" placeholder="Ivanov">
                <p v-if="isRequiredError && !customerSecondName.length" class="error-message-text">Обов'язкове поле</p>
                <p v-if="isRequiredError && customerSecondName.length && customerSecondName.length < 3" class="error-message-text">Поле має містити більше ніж 2 символи</p>
                    </div>
            <div class="make-order-input-container">
                 <label class="make-order-input-label" for="text">Номер телефону</label>
                      <input v-model="onOrderPhoneNumber" @input="validatePhoneNumber" @keyup.enter="handleEnterKey" class="make-order-input" type="text" id="text" placeholder="+380 63 633 63 63">
                      <p v-if="!isValidPhoneNumber" class="error-message-text">Введіть корректний номер телефону в форматі +380 або 380</p>
                    </div>
             <div v-if="isShowEmail" class="make-order-input-container">
                   <label class="make-order-input-label" for="text">Електронна адреса</label>
                        <input v-model="customerEmail" @blur="validateEmail" class="make-order-input" type="text" id="text" placeholder="abcd@mail.com">
                         <p v-if="isRequiredError && !customerEmail.length" class="error-message-text">Обов'язкове поле</p>
                          <p v-if="!validEmail" class="error-message-text">Формат електронної адреси не вірний</p>
                      </div>
                  <div v-if="isShowAddress">
                  <p class="make-order-page-title">Адреса доставки</p>
               <div class="make-order-input-container">
                       <label class="make-order-input-label" for="text">Місто</label>
                            <input v-model="customerCity" @input="debouncedSearch" @keyup.enter="handleEnterKey" class="make-order-input" type="text" id="text" placeholder="Введіть місто">
                              <p v-if="isRequiredError && !branchCity" class="error-message-text">Обов'язкове поле</p>
                            <div v-if="searchedTowns.length" class="searched-order-towns">
                          <vue-perfect-scrollbar class="perfect-town-scrollbar">
                            <p v-for="town of searchedTowns" :key="town.uuid_city" @click="onClickToCity(town)" class="searched-order-town-item">{{ town.name }}</p>
                          </vue-perfect-scrollbar>
                </div>
              </div>
              <div class="make-order-input-container">
                        <label class="make-order-input-label">Відділення Нової Пошти</label>
                             <input v-model="customerPostNumber" @focus="setIsShowBranch" @keyup.enter="handleEnterKey" autocomplete="off" class="make-order-input" type="text" id="branch-list" placeholder="Відділення №">
                               <p v-if="isRequiredError && !usedBranch" class="error-message-text">Обов'язкове поле</p>
                             <div v-if="isShowranch" class="searched-order-posts">
                              <input v-focus id="branch-list" type="text" placeholder="Пошук" @keyup.enter="handleEnterKey" v-model="filterPostText" class="searched-input-post">
                              <div v-if="!searchedBranch.length" class='branch-loading-container'>
                               <loader-spinner class="branch-loading-spinner"/>
                              <p id="branch-list" class="branch-loading-text">Зачекайте, йде завантаження доступних відділень</p>
                             </div>
                          <vue-perfect-scrollbar class="perfect-town-scrollbar">
                          <p v-for="branch of filteredPostNumbers" :key="branch.id" @click="onCLickToBranch(branch)" class="searched-order-town-item">{{ branch.text }}</p>
                        </vue-perfect-scrollbar>   
                      </div>
                    </div>
                  </div>
            <p class="make-order-page-title">Оплата</p>
            <p class="make-order-page-price">{{ `Списати наявні бали: ${setCounterAllPrice(cartElements)}` }}</p>

        </div>
        <div class="bottom-filter-button-container">
            <div class="error-message-container" v-if="isShowError">
            <p class="error-message-text">Під час оформлення замовлення сталася проблема, спробуйте знову</p>
          </div>
            <button v-if="!isLoadingFetch" class="filtration-button" @click="setMakeOrder">
               <div class="product-info-icon-container">
                <checked-custom-cart-icon/>
               </div>
              Оформити замовлення
            </button>
            <button v-else class="filtration-button">
              <loader-spinner class="button-loading-spinner" :LoaderColor="true"/>
              </button>
        </div>
    </div>
</template>

<script>

import { ChevronLeftIcon } from 'vue-feather-icons';
import debounce from 'lodash/debounce';
import { GET_CITYS, GET_ORDER_BRANCH } from '../helpers/requests';
import LoaderSpinner from '../../../components/LoaderSpinner.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CheckedCustomCartIcon from "../icons/CheckedCustomCartIcon.vue"

export default {
    name: "make-order-page",
    components: {
        ChevronLeftIcon,
        LoaderSpinner,
        VuePerfectScrollbar,
        CheckedCustomCartIcon
    },
    props: {

    },
    data() {
        return {
            productCounter: 0,
            customerName: '',
            customerSecondName: '',
            customerPostNumber: '',
            onOrderPhoneNumber: '+380',
            customerCity: '',
            filterPostText: '',
            customerEmail: '',
            isWrongFormat: false,
            isLoading: true,
            branchCity: null,
            usedBranch: null,
            isShowranch: false,
            searchedTowns: [],
            searchedBranch: [],
            filteredPostNumbers: [],
            resultMakePrder: null,
            isShowError: false,
            isLoadingFetch: false,
            isValidPhoneNumber: true,
            isRequiredError: false,
            isShowAddress: false,
            validEmail: true,
            isShowEmail: false
        }
    },
    mounted() {
      window.scrollTo(0, 0);
      this.isHavePhysicalProduct()
      window.addEventListener('click', (event) => {
        if (this.isShowranch && event.target.id !== 'branch-list') {
          this.isShowranch = false
        }
      })
      setTimeout(() => {
        const { first_name, last_name, phone } = this.customerInfo
        this.customerName = first_name
        this.customerSecondName = last_name
        this.onOrderPhoneNumber = phone
      }, 100)
    },
    computed: {
      getOrderId() {
        return this.$store.getters.getOrderId
      },
      cartElements() {
      return this.$store.getters.getCustomerCart;
      },
      customerInfo() {
        return this.$store.getters.customerInfo
      }
    },
    methods: {
        debouncedSearch: debounce(async function () {
          try {
            let data = {
              city: this.customerCity
            }
            if (this.customerCity.length < 3) {
              return
            }
           let result = await GET_CITYS(JSON.stringify(data))
           this.searchedTowns = result.data
          } catch (error) {
            console.log(error.message)
          }
       }, 300),
      handleEnterKey() {
            const activeElement = document.activeElement
            if (activeElement.tagName === 'INPUT') {
                activeElement.blur()
            }
        },
        validatePhoneNumber() {
        const ukrainePhoneRegex = /^(\+?38)?(0\d{9})$/;
        if (this.onOrderPhoneNumber?.length > 12) {
         this.isValidPhoneNumber = ukrainePhoneRegex.test(this.onOrderPhoneNumber);
        }
        },
       setIsShowBranch() {
        if (!this.branchCity) {
          return
        }
        setTimeout(() => {
          this.isShowranch = true
        }, 300)
       },
       isHavePhysicalProduct() {
        if (this.cartElements?.find(el => el.product_id.type === 'physical')) {
          this.isShowAddress = true
        } else {
          this.isShowAddress = false
        }
        if (this.cartElements?.find(el => el.product_id.type === 'digital')) {
          this.isShowEmail = true
        } else {
          this.isShowEmail = false
        }
       },
        validateEmail() {
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          this.validEmail = regex.test(this.customerEmail);
       },
       setHawePlusOnNumber(number) {
        if (number.startsWith("+")) {
          return number.slice(1)
        } else {
          return number
        }
       },
      setCounterAllPrice(arr) {
      let allPrice = 0
      arr.forEach(el => {
        allPrice += el.price * el.quantity
      });
      if (allPrice) {
        return allPrice
      } else {
        return 0
      }
    },
       setMakeOrder() {
        if (!this.isValidPhoneNumber || !this.validEmail) {
          return
        }
        if (this.isShowAddress) {
           if (
          !this.branchCity ||
          !this.usedBranch ||
          this.customerName?.length < 3 ||
          this.customerSecondName?.length < 3 ||
          this.customerEmail.length ||
          !this.onOrderPhoneNumber.length
        ) {
          this.isRequiredError = true
          return
        }
        } else {
            if (
          this.customerName?.length < 3 ||
          this.customerSecondName?.length < 3 ||
          !this.onOrderPhoneNumber.length
        ) {
          this.isRequiredError = true
          return
        } 
        }
        if (this.onOrderPhoneNumber.length < 11) {
          this.isValidPhoneNumber = false
        }
        this.isRequiredError = false
        this.isLoadingFetch = true
        let data 
        if (this.isShowAddress) {
          data = {
            customer_id: this.customerInfo.id,
            delivery_city_uuid: this.branchCity.uuid_city,
            delivery_city: this.branchCity.name,
            delivery_branch_uuid: this.usedBranch.id,
            delivery_branch: this.usedBranch.text,
            delivery_phone: this.setHawePlusOnNumber(this.onOrderPhoneNumber),
            delivery_first_name: this.customerName,
            delivery_last_name: this.customerSecondName
          }
        } else {
          data = {
            customer_id: this.customerInfo.id,
            delivery_phone: this.setHawePlusOnNumber(this.onOrderPhoneNumber),
            delivery_first_name: this.customerName,
            delivery_last_name: this.customerSecondName,
            delivery_email: this.customerEmail
          }
        }
        try {
         let result = this.$store.dispatch('setSaveOrder', JSON.stringify(data))
         result
         .then(value => this.resultMakePrder = value)
         .catch(error => this.resultMakePrder = error)
        } catch (error) {
          console.log(error.message)
        }
       },
       onClickToCity(value) {
        if (!value.uuid_city) {
          this.searchedTowns = []
          return
        }
        this.branchCity = value
        this.customerCity = value.name
        this.searchedTowns = []
        this.onSearchCitysBranch()
       },
       onCLickToBranch(value) {
        this.usedBranch = value
        this.customerPostNumber = value.text
        this.isShowranch = false
       },
      async onSearchCitysBranch() {
        if (!this.branchCity) {
          return
        }
        this.isLoading = true
        try {
          let data = {
            city_uuid: this.branchCity.uuid_city
          }
          await GET_ORDER_BRANCH(JSON.stringify(data)).then((value) => {
             this.searchedBranch = value.data
          }).catch((error) => alert(error.message))
        } catch (error) {
          console.log(error.message)
        }
        this.isLoading = false
       },
        setChangeCounter(value) {
            if (value === '+') {
                this.productCounter += 1
            } else {
                this.productCounter -= 1
            }
        },
    },
    watch: {
      customerPostNumber(newValue, oldVaue) {
         let val = oldVaue
      val
        this.filterPostText = newValue
      },
      branchCity(newValue, oldVaue) {
        let val = oldVaue
        val
        this.usedBranch = null
        this.customerPostNumber = ''
        this.searchedBranch = []
      },
      searchedBranch(newValue, oldVaue) {
        let val = oldVaue
        val
        this.filteredPostNumbers = newValue
      },
      filterPostText(newValue, oldValue) {
        let val = oldValue
        val
          if (newValue?.length >= 1) {
          const regex = new RegExp('.*' + newValue + '.*', 'i')
          let newArray = this.searchedBranch.filter(post => regex.test(post.text))
          this.filteredPostNumbers = newArray
          } else {
            this.filteredPostNumbers = this.searchedBranch
          }
      },
      resultMakePrder(newValue, oldValue) {
        let val = oldValue
        val
        this.isLoadingFetch = false
        if (newValue?.status) {
          this.$emit('setIsChangePage', 'success')       
        } else {
          this.isShowError = true
        }
      },
    }
}
</script>

<style scoped>
.main-background {
    background: #F3F3F5;
}
.filter-page-back-button {
    position: fixed;
    width: 100%;
    top: 0px;
    background: #F3F3F5;
    border-bottom: 1px solid #F0F0F0;
    padding: 18px;
    cursor: pointer;
    z-index: 10;
}

.back-button-icon {
    position: absolute;
}

.back-button-text {
    color: #4D4C4C;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.filter-page-container {
    display: flex;
    flex-direction: column;
}

.bottom-filter-button-container {
    background: #FFF;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    z-index: 100;
    position: fixed;
    width: 100%;
    bottom: 0px;
}

.product-info-icon-container {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 40px;
    height: 40px;
    background: #FFF;
    border-radius: 8px;
}

.product-info-icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.filtration-button {
     position: relative;
    border-radius: 8px;
    border: none;
    background: #EE1D23;
    color: #fff;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.return-to-shop {
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    color: #16ABB8;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    border: 1px solid #16ABB8;
}
.make-order-page-inputs-container {
  padding: 60px 16px 100px 16px;
  margin-top: 4px;
  overflow-y: scroll;
  height: 100%;
}

.make-order-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    position: relative;
}
.make-order-input-label {
    color: #707070;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.make-order-input {
    border-radius: 8px;
    border: 1px solid #D2D6DB;
    background: #FFF;
    color: #707070;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 15px;
    margin-top: 4px;
    outline-color: #EE1D23;
}

.make-order-input::placeholder {
   color: #d3d3d3;
}

.make-order-page-title {
    margin-top: 16px;
    color: #121212;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.make-order-page-main-title {
  color: #333;
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 10px;
}

.make-order-page-under-main-title {
  color: #333;
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

.searched-order-posts {
position: fixed;
    width: 90%;
    max-height: 350px;
    padding: 10px;
    left: 50%;
    transform: translateX(-50%);
    /* bottom: 120px; */
    top: 10px;
    z-index: 101;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.searched-order-towns {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #D2D6DB;
  border-radius: 8px;
  padding: 10px 5px;
  bottom: 45px;
}

.searched-order-town-item {
  padding: 10px;
  cursor: pointer;
  color: #707070;
  border-radius: 5px;
  transition: all .2s ease;
}

.searched-order-town-item:hover {
  background-color: #EE1D23;
  color: #fff;
}

.branch-loading-container {
  display: flex;
  gap: 10px;
}

.branch-loading-text {
  color: #707070;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}
.branch-loading-spinner {
  margin: auto 0;
}

.button-loading-spinner {
  margin: auto;
  height: 24px;
}
.perfect-town-scrollbar {
  height: 400px;
}

.searched-input-post {
 border-radius: 8px;
    border: 1px solid #D2D6DB;
    background: #FFF;
    color: #707070;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 15px;
    margin-top: 4px;
    outline-color:  #EE1D23;
    width: 100%;
    margin-bottom: 10px;
}

.error-message-container {
  padding: 5px 10px;
}

.error-message-text {
  font-size: 12px;
    font-weight: 400;
    color: red;
}

.make-order-page-price {
  color: #333;
font-family: Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
margin-top: 10px;
}

</style>