<template>
    <div class="main-background filter-page-container">
         <div class="filter-page-back-button" @click="returnToLastPage">
                <chevron-left-icon color="#4D4C4C"/>
                <p class="back-button-text">Корзина</p>
                 <div class="skip_btn">
                    <button class="to-cart-button">
                      <div v-if="cartElements.length" class="cart-counter">{{ cartElements.length }}</div>
                      <shopping-cart-icon color="#4D4C4C" size="24" ></shopping-cart-icon> 
                    </button>
                    </div>
              </div>
        <div v-if="cartElements.length" class="cart-page-container-list">
            <div v-for="item of cartElements" :key="item.id" class="cart-page-item">
                <img :src="item.product_id.img" alt="" class="cart-page-item-image" @click="onOpenProduct(item)">
                <div class="cart-page-item-info">
                    <button @click="deleteProduct(item)" class="delete-product-cart-button">
                        <x-icon class="delete-button-icon"/>
                    </button>
                    <p class="cart-page-item-name" @click="onOpenProduct(item)">{{ item.product_id.name }}</p>
                    <p class="cart-page-item-price">{{ item.product_id.price }} балів</p>
                    <input ref="myInput" type="text" class="cart-page-item-counter" @keyup.enter="handleEnterKey" v-model="item.quantity" @input="validateInput(item)" @change="setChangeProductCounter(item)">
                </div>
            </div>
        </div>
        <div v-else class="null-info-block">
            <frown-icon class="frown-icon"/>
            <p class="null-info-text">Ви поки не додали жодного товару</p>
        </div>
         <div v-if="cartElements.length" class="bottom-filter-button-container">
            <div class="final-price-container top">
            <p class="final-price-balance">Ваш баланс:</p>
            <p class="final-price-balance">{{ `${customerInfo.bonuses} балів` }}</p>
            </div>
            <div class="final-price-container bottom">
                <p class="final-price-counter" 
                :style="customerInfo.bonuses < setCounterAllPrice(cartElements) &&
                { color: '#C8392B' }">Всього:</p>
                <p class="final-price-counter" 
                :style="customerInfo.bonuses < setCounterAllPrice(cartElements) &&
                {color: '#C8392B'}">{{ `${setCounterAllPrice(cartElements)} балів` }}</p>
            </div>
                    <button 
                    class="filtration-button" 
                    @click="goToMakeOrderPage"
                    >
                     <div class="product-info-icon-container">
                        <checked-custom-cart-icon/>
                    </div>
                    Оформити замовлення
                </button>
              </div>
            <div v-else class="bottom-filter-button-container">
                        <button 
                            class="filtration-button" 
                            @click="returnToCatalog"
                            >
                            <div class="product-info-icon-container">
                            <search-custom-cart-icon/>
                            </div>
                            Повернутися в каталог
                        </button>
                        <button 
                            class="back-to-bot-button" 
                            @click="setReturnToChatBot"
                            >
                            Назад в бот
                    </button>
                  </div>
    </div>
</template>

<script>

import { 
    ChevronLeftIcon, 
    FrownIcon,
    XIcon 
} from 'vue-feather-icons'
import { ShoppingCartIcon } from 'vue-feather-icons'
import { mapMutations } from "vuex"
import CheckedCustomCartIcon from "../icons/CheckedCustomCartIcon.vue"
import SearchCustomCartIcon from "../icons/SearchCustomCartIcon.vue"

export default {
    name: "cart-page",
    components: {
        ChevronLeftIcon, 
        XIcon,
        ShoppingCartIcon,
        FrownIcon,
        CheckedCustomCartIcon,
        SearchCustomCartIcon
    },
    props: {
    },
    data() {
        return {
        }
    },
    async mounted() {
        window.scrollTo(0, 0);

        document.body.addEventListener('click', (event) => {
            let target = event.target
            console.log({target})
        })
    },

    methods: {
        ...mapMutations(['addPerPage']),
        handleEnterKey() {
            const activeElement = document.activeElement
            if (activeElement.tagName === 'INPUT') {
                activeElement.blur()
            }
        },
        setReturnToChatBot() {
                window.Telegram.WebApp.close()
        },
        returnToCatalog() {
            this.$emit('setIsChangePage', 'product')
        },
        onOpenProduct(item) {
            this.$store.dispatch("getOneProduct", item.product_id.id).then(() => {
             this.addPerPage('cart')
             this.$emit("setIsChangePage", "productInfo");
            })
        },
         validateInput(item) {
            item.quantity = item.quantity.replace(/\D/g, '');

             const numericValue = parseInt(item.quantit);
            if (numericValue > item.quantity) {
                item.quantity = item.product_id.quantity.toString();
            }
        },
        setChangeProductCounter(value) {
            let data
            if (Number(value.quantity) > value.product_id.quantity) {
                data = {
                    customer_id: this.customerInfo.id,
                    product_id: value.product_id.id,
                    quantity: value.product_id.quantity
                }
            } else {
                data = {
                    customer_id: this.customerInfo.id,
                    product_id: value.product_id.id,
                    quantity: value.quantity
                }
            }
            try {
                this.$store.dispatch('addProductToCart', JSON.stringify(data))  
            } catch (error) {
               console.log(error.message) 
            }
        },
        goToMakeOrderPage() {
            if (this.customerInfo.bonuses < this.setCounterAllPrice(this.cartElements)) {
                return
            }
            if (this.cartElements.length) {
                this.$emit('setIsChangePage', 'makeOrder')
            }
        },
        setCounterAllPrice(arr) {
            let allPrice = 0
            arr.forEach(el => {
                allPrice += el.price * el.quantity
            });
            if (allPrice) {
                return allPrice
            } else {
                return 0
            } 
        },
        setChangeCounter(value, product) {
               let data = {
                product_id: product.product_id,
                order_id: this.getOrderId.id
            }
            if (value === '+') {
                this.$store.dispatch('getOneMoreProduct', JSON.stringify(data))
            } else {
                 this.$store.dispatch('getMinusProduct', JSON.stringify(data))
            }
        },
        deleteProduct(product) {
            let data = {
               product_id: product.product_id.id,
               customer_id: this.customerInfo.id
            }
            try {
                this.$store.dispatch('deleteProductFromCart', JSON.stringify(data))
            } catch (error) {
                console.log(error.message)
            }
        },
        returnToLastPage() {
            if (this.perPageName) {
                this.$emit('setIsChangePage', this.perPageName)
                this.addPerPage('')
            } else {
                this.$emit('setIsChangePage', 'product')
            }
            
        }
    },
    computed: {
        cartElements() {
            return this.$store.getters.getCustomerCart;
        },
        getOrderId() {
            return this.$store.getters.getOrderId
        },
        perPageName() {
            return this.$store.getters.perPageName
        },
         customerInfo() {
            return this.$store.getters.customerInfo
        }
    },
    watch: {

    }
}
</script>

<style scoped>
.main-background {
    background: #F3F3F5;
}
.filter-page-back-button {
    position: fixed;
    width: 100%;
    top: 0px;
    border-bottom: 1px solid #F0F0F0;
    background: #F3F3F5;
    padding: 27px 16px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.back-button-icon {
    position: absolute;
}

.cart-page-item-info {
    max-width: 45%;
    display: flex;
    flex-direction: column;
}

.product-info-icon-container {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 40px;
    height: 40px;
    background: #FFF;
    border-radius: 8px;
}

.product-info-icon-container svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.back-button-text {
     color: #4D4C4C;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.filter-page-container {
    display: flex;
    flex-direction: column;
}
.bottom-filter-button-container {
  background: #FFF;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-top: auto;
  position: fixed;
    width: 100%;
    bottom: 0px;
}

.filtration-button {
    position: relative;
    border-radius: 8px;
    border: none;
    background: #EE1D23;
    color: #fff;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
}

.to-cart-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}
.cart-page-container-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 160px;
  padding-top: 80px;
  gap: 20px;
}
.cart-page-item {
    position: relative;
    display: flex;
    gap: 24px;
    padding: 6px 16px 16px 16px;
    border-bottom: 1px solid #F0F0F0;
}
.cart-page-item-image {
    border-radius: 12px;
    width: 140px;
    height: 140px;
    cursor: pointer;
}
.cart-page-item-name {
    color: #333;
    text-overflow: ellipsis;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.cart-page-item-price {
    color: #C8392B;
    font-family: Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-top: auto;
    margin-bottom: 3px;
}

.cart-page-item-counter {
    border-radius: 4px;
    border: 1px solid #8C8C8C;
    outline: none;
    color: #8C8C8C;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    padding: 4px;
    width: 40px;
    margin-bottom: 5px;
}
.final-price-container {
    display: flex;
    justify-content: space-between;
}
.final-price-container.top {
    margin-bottom: 12px;
}
.final-price-container.bottom {
    margin-bottom: 17px;
}
.final-price-balance {
    color: #333;
    font-family: Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.final-price-counter {
color: #333;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}

.delete-product-cart-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.delete-button-icon {
    color: #686868;
}

.cart-counter {
  position: absolute;
  height: 14px;
  min-width: 14px;
  background: #C8392B;
  border-radius: 50%;
  padding: 2px;
  color: #FFF;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 8px;
  top: -1px;
  right: -4px;
}

.null-info-block {
    border-radius: 12px;
    border: 1px solid #D2D6DB;
    background: #FFF;
    margin: 80px auto 0 auto;
    width: 345px;
    padding: 12px;
    display: flex;
    flex-direction: column;  
}

.frown-icon {
    width: 50px;
    height: 50px;
    color: #EE1D23;
    margin: 0 auto;
}

.null-info-text {
    color: #333;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    max-width: 230px;
    margin: 6px auto 0 auto;  
}

.back-to-bot-button {
  width: 100%;
  padding: 12px;  
  border-radius: 8px;
    border: 1px solid #EE1D23;
    background: transparent;
    color: #EE1D23;
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    margin-top: 10px;
}

</style>