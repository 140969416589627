<template>
    <div>
    <div class="top-navbar_full">
     <div class="back-btn">
                  <button type="button" class="logo-button" @click="goToMainPage">
                    <logo-icon />
                  </button>
        </div>
        <div class="skip_btn">
                <button class="to-cart-button" @click="goToCartPage">
                  <div v-if="cartElements.length" class="cart-counter">{{ cartElements.length }}</div>
                  <shopping-cart-icon size="24" ></shopping-cart-icon> 
                </button>
     </div>
    </div>
    <div class="main-background">
       <div class="category-container">
             <div v-for="product of getProducts" :key="product.id" class="product-block" @click="setClickToProduct(product)">
                <img :src="product.img" :alt="product.name" class="product-image">
                <div class="product-name-container">
                    <div class="product-name-info-container">
                         <p class="product-name">{{ product.name }}</p>
                        <p class="product-price">{{ `${product.price} балів` }} </p> 
                    </div>
                    <button type="button" class="add-to-cart-button" @click.stop="isAddToCart(product)">
                            <succes-custom-cart-icon class="add-to-cart-button-icon" v-if="isElementInCart(product.id)"/>
                            <shopping-cart-icon v-else size="18" class="add-to-cart-button-icon"></shopping-cart-icon> 
                    </button>
                </div>
               </div>
       </div>
    </div>
               <div class="bottom-filter-button-container">
                    <div class="bottom-balance-container">
                        Мій баланс:<span>{{ `${ customerInfo.bonuses || 0 } балів` }}</span> 
                    </div>
                </div>
</div>
</template>

<script>

import { mapMutations } from "vuex"
import { ShoppingCartIcon } from 'vue-feather-icons'
import LogoIcon from "../icons/LogoIcon.vue"
import SuccesCustomCartIcon from "../icons/SuccesCustomCartIcon.vue"

export default {
    name: "product-page",
    components: {
        ShoppingCartIcon,
        LogoIcon,
        SuccesCustomCartIcon
    },
    props: {

    },
    data() {
        return {
            usedCategories: {},
        }
    },
    async mounted() {
        window.scrollTo(0, 0);
    },
    computed: {
      getProducts() {
         return this.$store.getters.getProducts
      },
       getSearchText() {
            return this.$store.getters.getSearchText
      },
      getFilterParametrs() {
        return this.$store.getters.getFilterParametrs
      },
      getCheckedCategory() {
        return this.$store.getters.getCheckedCategory
      },
      cartElements() {
        return this.$store.getters.getCustomerCart;
      },
      customerInfo() {
        return this.$store.getters.customerInfo
      },
    },
    methods: {
      ...mapMutations(['setIsOpenProductInfo', 'setChangeSearchText', 'addPerPage', 'setCheckedCategory']),

    setClickToProduct(value) {
        this.$store.dispatch('getOneProduct', value.id).then(() => {
          this.$emit('setIsOpenProductPage')
        })
        },
    goToMainPage() {
        this.$emit('setIsChangePage', 'product')
    },
    goToCartPage() {
       this.$emit('setIsChangePage', 'cart') 
    },
    isElementInCart(id) {
      if (!this.cartElements.length) {
        return
      }
      if (this.cartElements.find(el => el.product_id.id === id)) {
        return true
      } else {
        return false
      }
    },
    isAddToCart(product) {
      if (this.cartElements?.find(el => el.product_id.id === product.id)) {
        this.$emit('setIsChangePage', 'cart')
      }
      let data = {
        customer_id: this.customerInfo.id,
        product_id: product.id
      }
      try {
        this.$store.dispatch('addProductToCart', JSON.stringify(data)).then(() => {
          this.$emit('setIsChangePage', 'cart')
        })
      } catch (error) {
        console.log(error.message)
      }
    }
    },
    watch: {

    }
}
</script>

<style scoped>
.main-background {
    background: #F3F3F5;
    padding: 75px 16px 80px 16px;
    height: 100%;
    overflow-y: scroll;
}
 .category-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
 }

 .product-block {
    width: 47%;
    border-radius: 12px;
    background: #ffffff;
    cursor: pointer;
 }
 .product-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 160px;
    height: 150px;
    border-radius: 10px 10px 0px 0px;
 }
 .product-name-container {
    display: flex;
    justify-content: space-between;
    padding: 12px;
 }

 .product-name-info-container {
  width: 70%;
 }
 .product-name {
    color: #333;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
 }
 .product-price {
    color: #EE1D23;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-top: 5px;
 }

.bottom-filter-button-container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #FFF;
  padding: 16px;
  margin-top: auto;
}

.bottom-balance-container {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 8px;
    background: rgba(238, 29, 35, 0.10);
    text-align: center;
    width: 200px;
    color: #EE1D23;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.bottom-balance-container span {
  font-weight: 700;
}

.add-to-cart-button {
    background: #F0F2F6;
    border: none;
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
}

.add-to-cart-button-icon {
    display: block;
    margin: auto;
}

.top-navbar_full {
    position: fixed;
    width: 100%;
    top: 0px;
    background: #F3F3F5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px 0px;
    border-bottom: 1px solid #F0F0F0;
}

.to-cart-button,
.logo-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.skip_btn {
    margin-top: 13px;
}

.cart-counter {
  position: absolute;
  height: 14px;
  min-width: 14px;
  background: #C8392B;
  border-radius: 50%;
  padding: 2px;
  color: #FFF;
  font-family: 'Lato', sans-serif;
  font-size: 8px;
  font-weight: 700;
  line-height: 8px;
  top: -1px;
  right: -4px;
}

</style>